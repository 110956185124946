<template>
  <el-card class="box-card">
    <el-collapse v-model="activeName" accordion>
      <el-collapse-item name="1">
        <template slot="title">
          <i class="header-icon el-icon-search"></i> 搜索
        </template>
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item label="积分来源:">
            <el-select v-model="queryForm.growth_value_source" placeholder="请选择成长值来源" size="small">
              <el-option label="0" value="0"></el-option>
              <el-option label="1" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="操作时间:">
            <el-date-picker
                v-model="query_time"
                size="small"
                :default-time="['00:00:00', '23:59:59']"
                end-placeholder="结束日期"
                format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="开始日期"
                type="daterange"
                value-format="yyyy-MM-dd HH:mm:ss"
                @change="getTimeSection"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" @click="queryData" type="primary" plain size="small">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </el-collapse-item>
    </el-collapse>

    <vab-query-form-left-panel>
      <el-button class="button" type="primary" @click="exportExcel" size="small">导出数据</el-button>
    </vab-query-form-left-panel>

    <el-table
        :data="list"
        style="width: 100%">
      <el-table-column
          align="center"
          prop="id"
          label="ID"
          width="80">
      </el-table-column>
      <el-table-column
          align="center"
          prop="growth_value_source"
          label="成长值来源">
      </el-table-column>
      <el-table-column
          align="center"
          prop="order_sn"
          label="订单号">
      </el-table-column>
      <el-table-column
          align="center"
          prop="growth_value_change"
          label="积分变化">
      </el-table-column>
      <el-table-column
          align="center"
          label="时间"
          prop="create_at"
          show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div>{{ scope.row.create_at | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="notes"
          label="备注">
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :current-page="queryForm.page"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <Edit ref="Edit" />
  </el-card>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import Edit from './components/level_Edit'

export default {
  name: 'growthValueDetails',
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    },
  },
  components: { Edit },
  props: [],
  data() {
    return {
      activeName: '1',
      imgShow: true,
      apiULId: [],
      query_time: [],
      selectRows: '',
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        growth_value_source: '',
        start_date: '',
        end_date: '',
      },
      list: [
        {
          id: '1',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          merchant_id: '1',
          is_check: '0',
        },
        {
          id: '2',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          merchant_id: '1',
          is_check: '1',
        },
        {
          id: '3',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          merchant_id: '1',
          is_check: '2',
        },
      ],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    //获取时间段
    getTimeSection(event) {
      console.log(event)
      if (event != null) {
        this.queryForm.start_date = event[0]
        this.queryForm.end_date = event[1]
      } else {
        this.queryForm.start_date = ''
        this.queryForm.end_date = ''
      }
    },
    // 导出
    async exportExcel() {
      const { data } = await exportOfflineMoney()
      console.log(data)
      window.location.href = data[0]
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      const { data } = await getList(this.queryForm)
      this.list = data.list
      this.count = data.count
      this.count = parseInt(data.count)
      if (!this.list.length) this.queryForm.page++
      this.listLoading = false
    },
  }
}

</script>
<style>
  .button {
    margin-top: 20px;
  }

  .el-pagination {
    text-align: center;
    margin-top: 20px;
  }

  .el-form-item {
    margin-bottom: 0;
  }

  .el-dropdown {
    margin-right: 10px;
  }

  .page {
    background-color: #FFF;
    padding: 20px;
    margin: 20px;
  }

  .el-row {
    margin-bottom: 15px;
  }
</style>
